import type {
  BrokerExecutionVenueTypes,
  ConfirmationMode,
  Counterpart,
  Way,
} from '../../../../../neos/business/neosModel';
import type {
  ActivityType,
  BidAsk,
  BookingId,
  OnyxAllocConfirmationMedia,
  OnyxClearingInfo,
  OnyxCommissionInfo,
  OnyxCounterparty,
  OnyxFairPrice,
  OnyxIndependantAmount,
  OnyxProduct,
  OnyxQuote,
  PriceWithUnit,
  SecondaryOnyxIndependantAmount,
  SizeType,
} from '../../../../../neos/business/neosOnyxModel';
import type { EventType, SettlementInfo } from './legData/legDataModel';
import type { ClientPosition } from './legModel';
import type { UpdateJustification } from './otcAllocation/updateJustificationModel';

export * from './product/productOnyxModel';

export interface LegExecution {
  numberOfLots?: number;
  lastPrice?: PriceWithUnit;
  execUuid?: string;
}

export interface OnyxLeg {
  uuid: string;
  activity?: ActivityType;
  bookingId?: BookingId;
  clientWay: Way | undefined;
  clientPosition?: ClientPosition;
  sgWay?: Way;
  negotiatedSize: OnyxNegotiatedSize;
  product: OnyxProduct;
  quote?: OnyxQuote;
  fairPrice?: OnyxFairPrice;
  master: boolean;
  weight: number;
  screenPrice?: BidAsk;
  settlement?: SettlementInfo;
  totalExecutedSize?: OnyxNegotiatedSize;
  averageExecutedPrice?: PriceWithUnit;
  allocations: OnyxAllocation[] | undefined;
  cancelledAllocations: OnyxAllocation[] | undefined;
  legAssociations?: OnyxLegAssociation[];
  legReferenceUuids?: OnyxLegReference[];
  eventType?: EventType;
  executionDetails?: LegExecution[];
  taxCollection?: boolean;
  broker?: { id?: number };
  brokerCommission?: PriceWithUnit;
  brokerInfo?: {
    venue?: string;
    tradingVenueTransactionId?: string;
    tradingDateTime?: string;
    executionVenue?: BrokerExecutionVenueTypes;
  };
}

export interface OnyxLegAssociation {
  associationActivity: EventType | undefined;
  primaryRfqId:
    | {
        id: string;
        application: string;
      }
    | undefined;
}

export interface OnyxLegReference {
  rfqId: { application: 'XONE'; id: string } | undefined;
  legId: { application: 'XONE'; id: string } | undefined;
}

export type UnderlyingIndexType = 'INDEX' | 'INDEX_COMMODITY';
export type UnderlyingStockType = 'STOCK';
export type UnderlyingType =
  | UnderlyingIndexType
  | UnderlyingStockType
  | 'ETF'
  | 'CUSTOM'
  | 'PREFERENCE_SHARE'
  | 'ADR'
  | 'BASKET'
  | 'THEMATIC_BASKET';

export function isUnderlyingIndexType(type: UnderlyingType): type is UnderlyingIndexType {
  const eligibleTypes: UnderlyingIndexType[] = ['INDEX', 'INDEX_COMMODITY'];
  return (eligibleTypes as UnderlyingType[]).includes(type);
}

export function isUnderlyingStockType(type: UnderlyingType): type is UnderlyingStockType {
  return type === 'STOCK';
}

export interface OnyxNegotiatedSize {
  numberOfLots?: number;
  notional?: PriceWithUnit;
  localNotional?: PriceWithUnit;
  sizeType?: SizeType;
  quantity?: number;
  varUnit?: PriceWithUnit;
}

export interface AbstractOnyxAllocation {
  uuid: string;
  counterparty: OnyxCounterparty | undefined;
  negotiatedSize: OnyxNegotiatedSize | undefined;
  broker: OnyxCounterparty | undefined;
  externalReferenceId?: {
    application: 'XONE';
    id: string | undefined;
  };
}

export interface OnyxListedAllocation extends AbstractOnyxAllocation {
  negotiationMode: 'LISTED';
  clearingInfo: OnyxClearingInfo | undefined;
  commissionInfo: OnyxCommissionInfo | undefined;
  execUuid: string;
}

export interface OnyxDeltaStockListedAllocation extends AbstractOnyxAllocation {
  negotiationMode: 'LISTED';
  comment: string | undefined;
}

export interface OnyxOtcAllocation extends AbstractOnyxAllocation {
  negotiationMode: 'OTC';
  independentAmount: OnyxIndependantAmount | undefined;
  independentAmountCurrency?: PriceWithUnit;
  confirmationMedia: OnyxAllocConfirmationMedia | undefined;
  secondaryInfo?: SecondaryInfo;
  stepInInfo?: StepInInfo;
  mcaEligible: boolean | undefined;
  salesCreditReferenceId?: {
    application: string | undefined;
    id: string | undefined;
  };
  markitWireEligible: boolean | undefined;
  updateJustificationType: UpdateJustification | undefined;
  confirmationMode?: ConfirmationMode;
}

export interface ApplicationId {
  application: string;
  id: string;
}

export type ElsClsFlowModeType =
  | 'Split Flow and Pay at Value Date'
  | 'Do Not Split'
  | 'Split Flow and Keep Payment Date';

export interface SecondaryInfo {
  primaryBookingId?: ApplicationId;
  remainingQuantity?: number;
  amountDue?: SecondaryOnyxIndependantAmount;
  novationFee?: SecondaryOnyxIndependantAmount;
  valueDate?: string;
  amountDueWay?: 'SG_RECEIVE' | 'SG_PAY';
  novationFeeWay?: 'SG_RECEIVE' | 'SG_PAY';
  transferor?: Counterpart;
  transferee?: Counterpart;
  remainingParty?: Counterpart;
  confirmationToBeChecked?: boolean;
  partialReset?: boolean;
  observeNewRateFixing?: boolean;
  dividendCurrentFlowMode?: ElsClsFlowModeType;
  equityCurrentFlowMode?: ElsClsFlowModeType;
  rateCurrentFlowMode?: ElsClsFlowModeType;
  keepDividendPaymentDate?: boolean;
  flowMode?: ElsClsFlowModeType;
  mtm?: boolean;
  usePositionFromLastReset?: boolean;
  indexCashFlow?: number;
  feeCashFlow?: number;
  interestCashFlow?: number;
}

export interface StepInInfo {
  initialTradeDate?: string;
  initialStrike?: PriceWithUnit;
  transferor?: Counterpart;
  remainingParty?: Counterpart;
}

export type OnyxAllocation =
  | OnyxListedAllocation
  | OnyxOtcAllocation
  | OnyxDeltaStockListedAllocation;

import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { isUnderlyingIndexType } from '@/neos/business/rfq/strategy/leg/legOnyxModel';
import { orderOf } from '@/neos/business/services/statusOrder';
import type { AllocationsModel } from '@/neos/components/rfq/postNego/allocs/getAllocationsModel';

export interface AllLegsMatchingModel {
  isDisplayed: boolean;
  allLegsAreMatching: boolean;
  isMatchingDisabled: boolean;
  isInsertionDisabled: boolean;
  areButtonsInSync: boolean;
}

export function getMatchingInsertionToggleModel(
  state: AppState,
  rfqId: string,
  { strategyLegs, deltaLegs }: Partial<AllocationsModel>,
  isDelta: boolean,
  selectors: Selectors,
): AllLegsMatchingModel {
  const hasRFQBookingSteps = selectors.hasRFQBookingSteps(state, rfqId);
  const { status } = selectors.getRfqData(state, rfqId);
  const isStatusBookingRequested = status === 'BOOKING_REQUESTED';
  const isStatusPreTradeBookingRequested = status === 'PRE_TRADE_BOOKING_REQUESTED';
  const isStatusBetweenOrderCrossedAndBookingRequestedExcluded = orderOf(
    status,
  ).isBetweenRightExclude('ORDER_CROSSED', 'BOOKING_REQUESTED');

  const isCriteriaMatchingToggleEnabled = selectors.isFeatureToggleEnabled(
    state,
    'neos.matching.criteria.enabled',
  );

  const isFuturesMatchingToggleEnabled = selectors.isFeatureToggleEnabled(
    state,
    'neos.matching.futures.enabled',
  );
  const isMatchingToggleEnabled = isCriteriaMatchingToggleEnabled || isFuturesMatchingToggleEnabled;
  const isUserTrader = selectors.isTrader(state);

  const deltaExchangeListedLegs = deltaLegs?.filter(({ strategyId }) => {
    const strategyData = selectors.getStrategyData(state, strategyId);
    return strategyData.scope === 'DELTA_EXCHANGE';
  });

  const areSomeDeltaExchangeListedLegsWithIndexUnderlying =
    deltaExchangeListedLegs !== undefined &&
    deltaExchangeListedLegs.some(({ legsModel }) => {
      return legsModel.some(({ legId }) => {
        const underlyingId = selectors.getUnderlyingOrRefIdOfLeg(state, legId, selectors);
        const underlyingType = selectors.getUnderlyingInfo(state, underlyingId)?.type;
        if (!underlyingType) {
          return false;
        }

        return isUnderlyingIndexType(underlyingType);
      });
    });

  const strategyListedLegIds = strategyLegs?.flatMap(strategy =>
    strategy.legsModel.filter(leg => !leg.isOtc).map(leg => leg.legId),
  );
  const areSomeStrategyLegsListed: boolean = !!strategyListedLegIds?.length;
  const strategyListedLegOrders = strategyListedLegIds?.map(legId =>
    selectors.getOrderByLegId(state.orderData, rfqId, legId),
  );
  const hasStrategyLegsOneManualOrder: boolean = !!strategyListedLegOrders?.some(
    order => order?.manual,
  );
  const areStrategyListedIndexLegs = areSomeStrategyLegsListed && hasStrategyLegsOneManualOrder;

  const isLeftPartDisplayed = !isDelta && areStrategyListedIndexLegs;
  const isDeltaButtonDisplayed = isDelta && areSomeDeltaExchangeListedLegsWithIndexUnderlying;

  const isStatusCorrect =
    isStatusBetweenOrderCrossedAndBookingRequestedExcluded ||
    isStatusBookingRequested ||
    isStatusPreTradeBookingRequested;

  const isDisplayed =
    isUserTrader &&
    isMatchingToggleEnabled &&
    isStatusCorrect &&
    (isLeftPartDisplayed || isDeltaButtonDisplayed);

  const currentLegs = isDelta ? deltaLegs : strategyLegs;
  const currentListedLegIds = currentLegs?.flatMap(strategy =>
    strategy.legsModel.filter(leg => !leg.isOtc).map(leg => leg.legId),
  );
  const currentListedLegOrders = currentListedLegIds?.map(legId =>
    selectors.getOrderByLegId(state.orderData, rfqId, legId),
  );
  const allCurrentLegsAreMatching = !!currentListedLegOrders?.every(order => order?.matching);

  const workflowHasFailedBookingSteps =
    (isStatusBookingRequested || isStatusPreTradeBookingRequested) &&
    hasRFQBookingSteps &&
    !allCurrentLegsAreMatching;

  const isMatchingDisabled =
    workflowHasFailedBookingSteps || isStatusBetweenOrderCrossedAndBookingRequestedExcluded;

  const workflowHasAtLeastOneMatchedBookingId = selectors.getWorkflowHasAtLeastOneMatchedBookingId(
    state,
    rfqId,
    selectors,
  );

  const isInsertionDisabled =
    workflowHasAtLeastOneMatchedBookingId ||
    workflowHasFailedBookingSteps ||
    isStatusBetweenOrderCrossedAndBookingRequestedExcluded;

  const areButtonsInSync =
    areStrategyListedIndexLegs && areSomeDeltaExchangeListedLegsWithIndexUnderlying;

  return {
    isDisplayed,
    allLegsAreMatching: allCurrentLegsAreMatching,
    isMatchingDisabled,
    isInsertionDisabled,
    areButtonsInSync,
  };
}

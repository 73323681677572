import {
  type ConfirmationMode,
  ConfirmationModeValues,
} from '@/neos/business/rfq/strategy/leg/otcAllocation/otcAllocationModel.ts';
import { NeosSelectWithAutocomplete } from '@/neos/components/share/neosSelectWithAutocomplete/NeosSelectWithAutocomplete.tsx';
import { mapRecordEntriesToSelectOptions } from '@/util/array/arrayUtils.ts';
import { neosActionCreators } from '@/neos/business/neosActionCreators.ts';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/bootstrap/hooks.ts';
import { selectors } from '@/bootstrap/selectors.ts';

interface ConfirmationModeProps {
  rfqId: string;
  strategyId: string;
}

export function ConfirmationModeComponent({ rfqId, strategyId }: ConfirmationModeProps) {
  const dispatch = useDispatch();

  const isEls = useAppSelector(state => selectors.isElsStrategy(state, strategyId, selectors));
  const { internal } = useAppSelector(state => selectors.getRfqData(state, rfqId));
  const { confirmationMode } = useAppSelector(state =>
    selectors.getStrategyData(state, strategyId),
  );

  if (!isEls || !internal) {
    return null;
  }

  if (confirmationMode === undefined) {
    onConfirmationModeChanged(ConfirmationModeValues.ST as ConfirmationMode);
  }

  function onConfirmationModeChanged(newValue: ConfirmationMode | undefined) {
    dispatch(
      neosActionCreators.strategyDataCrudActions.update(strategyId, { confirmationMode: newValue }),
    );
  }

  return (
    <div>
      <label>Confirm. Mode</label>
      <NeosSelectWithAutocomplete
        value={confirmationMode}
        onChange={selected => onConfirmationModeChanged(selected?.value as ConfirmationMode)}
        data-e2e="leg-confirmation-mode"
        options={mapRecordEntriesToSelectOptions(ConfirmationModeValues)}
      />
    </div>
  );
}

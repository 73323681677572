import { actionCreators } from '@/bootstrap/actions';
import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import type { UnderlyingType } from '@/neos/business/rfq/strategy/leg/legOnyxModel';
import type {
  BasketCompositionDetails,
  ElsBasket,
} from '@/neos/business/rfq/strategy/leg/product/productModel';
import { If } from '@/neos/components/share/if/if';
import { useDispatch } from 'react-redux';

type GridRowProps = {
  index: number;
  compositionDetails: BasketCompositionDetails;
  rfqId: string;
  product: ElsBasket;
  underlyingTypes: UnderlyingType[];
  exclude: string[];
};

export function SimplerBasketCompositionDetailsGridRow(gridRowProps: GridRowProps) {
  const { product, index, compositionDetails, rfqId } = gridRowProps;

  const dispatch = useDispatch();
  const { underlyingId, execFeesOut, execFeesIn } = compositionDetails;
  const underlyingDetails = useAppSelector(state =>
    selectors.getUnderlyingInfo(state, underlyingId),
  );
  const reference = useAppSelector(state =>
    underlyingId ? selectors.getReference(state, { rfqId, underlyingId }) : undefined,
  );

  function onDelete() {
    dispatch(
      actionCreators.neos.productCrudActions.update(product.uuid, {
        basketUnderlying: {
          ...product.basketUnderlying,
          basketComposition: product.basketUnderlying.basketComposition.toSpliced(index, 1),
        },
      }),
    );
    dispatch(thunks.neos.createDefaultBasketProductThunk(rfqId));
  }

  return (
    <div style={{ display: 'contents' }}>
      <button className="btn btn-icon btn-flat-primary" onClick={onDelete}>
        <i className="icon icon-md">delete_forever</i>
      </button>
      <div className={'form-control readonly'}>{underlyingDetails?.bloombergCode}</div>
      <div className={'form-control readonly'}>{compositionDetails.weight}</div>
      <div className={'form-control readonly'}>{compositionDetails.quantity}</div>
      <div className={'form-control readonly'}>
        {reference?.refSpotUnit} {reference?.refSpot}
      </div>
      <If
        condition={
          product.basketUnderlying.execFees === 'IN' ||
          product.basketUnderlying.execFees === 'IN_AND_OUT'
        }
      >
        <div className={'form-control readonly'}>
          {execFeesIn?.unit ?? 'bp'} {compositionDetails.execFeesIn?.value}
        </div>
      </If>

      <If
        condition={
          product.basketUnderlying.execFees === 'OUT' ||
          product.basketUnderlying.execFees === 'IN_AND_OUT'
        }
      >
        <div className={'form-control readonly'}>
          {execFeesOut?.unit ?? 'bp'} {compositionDetails.execFeesOut?.value}
        </div>
      </If>
      <div className={'form-control readonly'}>
        {reference?.refSpotNetUnit} {reference?.refSpotNet}
      </div>
      <div className={'form-control readonly'}>
        {reference?.refSpotNetSwapCurrencyUnit} {reference?.refSpotNetSwapCurrency}
      </div>
      <div className={'form-control readonly'}>
        {compositionDetails?.nominal?.unit} {compositionDetails?.nominal?.value}
      </div>
    </div>
  );
}

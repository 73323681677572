import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import type {
  ElsBasket,
  ExecFeesValue,
} from '@/neos/business/rfq/strategy/leg/product/productModel';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { useDispatch } from 'react-redux';

import { BasketCompositionDetailsGridRow } from '@/neos/components/rfq/strategies/strategy/features/elsBasket/BasketCompositionDetailsGridRow.tsx';
import { SimplerBasketCompositionDetailsGridRow } from '@/neos/components/rfq/strategies/strategy/features/elsBasket/SimplerBasketCompositionDetailsGridRow.tsx';

interface ElsBasketDescriptionProps {
  rfqId: string;
  strategyId: string;
  product: ElsBasket;
}

const labelClassName = 'align-content-center sticky-top fw-bold bg-lvl1 h-100';
const READONLY_THRESHOLD = 200;

export function ElsBasketComposition({ rfqId, strategyId, product }: ElsBasketDescriptionProps) {
  const dispatch = useDispatch();
  const underlyingTypes = useAppSelector(state =>
    selectors.getUnderlyingTypes(state, strategyId, selectors),
  );
  const alreadySelectedUnderlyings = product.basketUnderlying.basketComposition
    .map(u => u.underlyingId)
    .filter(isDefined);

  const onAddNewLine = () => {
    dispatch(thunks.neos.createAddNewElsBasketCompositionItemThunk(product.uuid));
  };

  const { execFees } = product.basketUnderlying;
  const numberOfColumn = getNumberOfColumn(execFees);

  return (
    <div
      className="d-grid align-items-center gap-1 overflow-auto"
      style={{ gridTemplateColumns: `32px repeat(${7 + numberOfColumn}, 1fr)`, maxHeight: '400px' }}
    >
      <button className="sticky-top btn btn-icon btn-flat-primary bg-lvl1" onClick={onAddNewLine}>
        <i className="icon icon-md">add</i>
      </button>
      <label className={labelClassName}>Underlying</label>
      <label className={labelClassName}>Weight</label>
      <label className={labelClassName}>Quantity</label>
      <label className={labelClassName}>Spot</label>
      <BasketExecFeesInLabel execFees={execFees} />
      <BasketExecFeesOutLabel execFees={execFees} />
      <label className={labelClassName}>Spot Net</label>
      <label className={labelClassName}>Spot Net in Swap Ccy</label>
      <label className={labelClassName}>Nominal</label>

      {product.basketUnderlying.basketComposition.length > READONLY_THRESHOLD
        ? product.basketUnderlying.basketComposition.map((compositionDetails, index) => (
            <SimplerBasketCompositionDetailsGridRow
              product={product}
              rfqId={rfqId}
              underlyingTypes={underlyingTypes}
              compositionDetails={compositionDetails}
              index={index}
              key={compositionDetails.containerIndex}
              exclude={alreadySelectedUnderlyings}
            />
          ))
        : product.basketUnderlying.basketComposition.map((compositionDetails, index) => (
            <BasketCompositionDetailsGridRow
              product={product}
              rfqId={rfqId}
              underlyingTypes={underlyingTypes}
              compositionDetails={compositionDetails}
              index={index}
              key={compositionDetails.containerIndex}
              exclude={alreadySelectedUnderlyings}
            />
          ))}
    </div>
  );
}

function BasketExecFeesInLabel({ execFees }: { execFees: ExecFeesValue }) {
  if (execFees !== 'IN' && execFees !== 'IN_AND_OUT') {
    return null;
  }
  return <label className={labelClassName}>Exec Fees In</label>;
}
function BasketExecFeesOutLabel({ execFees }: { execFees: ExecFeesValue }) {
  if (execFees !== 'OUT' && execFees !== 'IN_AND_OUT') {
    return null;
  }
  return <label className={labelClassName}>Exec Fees Out</label>;
}

function getNumberOfColumn(execFeesKind: ExecFeesValue): number {
  if (execFeesKind === 'IN' || execFeesKind === 'OUT') {
    return 1;
  }
  if (execFeesKind === 'IN_AND_OUT') {
    return 2;
  }
  return 0;
}

import { thunks } from '@/bootstrap/thunks';
import type { BookingStep } from '@/neos/business/bookingSteps/BookingStepsSelectors';
import type { BookingStepStatus } from '@/neos/business/bookingSteps/bookingStepOnyxModel';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import type { OtcAllocation } from '@/neos/business/neosModel';
import type { AllocationUi } from '@/neos/business/ui/allocation/allocationUiModel';
import type { DisplayNegotiatedSize } from '@/neos/business/ui/strategy/strategyUiModel';
import type { AllocBookingInfo } from '@/neos/components/rfq/postNego/allocs/otcAllocations/OtcAllocations';
import { OtcAllocationSecondaryTypeWidget } from '@/neos/components/rfq/postNego/allocs/otcAllocations/otcAllocationFields/OtcAllocationSecondaryTypeWidget/OtcAllocationSecondaryTypeWidget';
import { NeosBookingId } from '@/neos/components/share/NeosBookingId/NeosBookingId';
import { If } from '@/neos/components/share/if/if';
import { useDispatch } from 'react-redux';
import { AllocationMcaEligibility } from './otcAllocationFields/AllocationMcaEligibility';
import { BrokenLink } from './otcAllocationFields/BrokenLink';
import { DownloadAllocationFile } from './otcAllocationFields/DownloadUpload/DownloadFile';
import { UploadFile } from './otcAllocationFields/DownloadUpload/UploadFile';
import { IAValueDate } from './otcAllocationFields/IndependentAmount/IAValueDate';
import { IndependentAmount } from './otcAllocationFields/IndependentAmount/IndependentAmount';
import { OtcAllocCounterpart } from './otcAllocationFields/OtcAllocCounterpart';
import { OtcAllocForcedReason } from './otcAllocationFields/OtcAllocForcedReason';
import { OtcAllocMedia } from './otcAllocationFields/OtcAllocMedia';
import { OtcAllocPdc } from './otcAllocationFields/OtcAllocPdc';
import { OtcAllocSalesMargin } from './otcAllocationFields/OtcAllocSalesMargin';
import { OtcAllocSize } from './otcAllocationFields/OtcAllocSize';
import { SalesCreditManualBooking } from './otcAllocationFields/SalesCreditManualBooking';
import { OtcAllocMarkitWireOptions } from './otcAllocationFields/markitWire/OtcAllocMarkitWireOptions';
import { SecondaryAllocationsFields } from './secondaryAllocations/SecondaryAllocationsFields';
import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { OtcAllocConfirmationMode } from '@/neos/components/rfq/postNego/allocs/otcAllocations/otcAllocationFields/OtcAllocConfirmationMode.tsx';

export interface SalesMarginAmountInfo {
  status: BookingStepStatus | undefined;
  value: number | undefined;
  unit: string | undefined;
  lastUpdateTime: BookingStep['lastUpdateTime'];
}

export interface OtcAllocationProps {
  index: number;
  rfqId: string;
  legId: string;
  strategyId: string;
  alloc: OtcAllocation;
  error: AllocationUi['errors'];
  shouldShowForcedReasonColumn: boolean;
  hasMarkitAlloc: boolean;
  uiSizeType: DisplayNegotiatedSize;
  hasOtcAllocationBookingStep: boolean;
  bookingInfo: AllocBookingInfo | undefined;
  areFileIconsDisplayed: boolean;
  isSalesMarginDisplayed: boolean;
  salesMarginValue: SalesMarginAmountInfo | undefined;
  isSecondaryStrategy: boolean;
  isSecondaryNovation: boolean;
  hasFailedSalesMarginBookings: boolean;
  shouldShowIAToBeBookedColumn: boolean;
  haveSomeBrokenLinkAllocations: boolean;
  meteorBookingStep: BookingStep | undefined;
  isConfirmationModeDisplayed: boolean;
}

export const OtcAllocationInfo = ({
  index,
  rfqId,
  uiSizeType,
  alloc,
  error,
  strategyId,
  legId,
  hasMarkitAlloc,
  shouldShowForcedReasonColumn,
  hasOtcAllocationBookingStep,
  areFileIconsDisplayed,
  isSalesMarginDisplayed,
  salesMarginValue,
  hasFailedSalesMarginBookings,
  shouldShowIAToBeBookedColumn,
  haveSomeBrokenLinkAllocations,
  bookingInfo,
  meteorBookingStep,
  isConfirmationModeDisplayed,
}: OtcAllocationProps) => {
  const dispatch = useDispatch();
  const isAllocationCancelled = alloc.isCancelled;
  const hasBrokenLink = useAppSelector(
    state => selectors.getOtcAllocationBookingStep(state, rfqId, alloc.uuid)?.brokenLink,
  );

  function onAllocationRemoved(allocationId: string, allocationBookingId: string | undefined) {
    dispatch(
      thunks.neos.createDeleteOtcAllocationThunk(rfqId, legId, allocationId, allocationBookingId),
    );
  }
  function onBookingIdChanged(allocationId: string, bookingId?: string) {
    dispatch([
      neosActionCreators.otcAllocationCrudActions.update(
        { allocationId, rfqId, legId },
        {
          externalReferenceId: {
            application: 'XONE',
            id: bookingId,
          },
        },
      ),
    ]);
  }

  return (
    <>
      <button
        className="btn btn-icon btn-flat-primary"
        onClick={() => onAllocationRemoved(alloc.uuid, bookingInfo?.bookingId)}
        data-e2e="remove-otc-alloc"
      >
        <i className="icon icon-md">{isAllocationCancelled ? 'clear' : 'delete_forever'}</i>
      </button>

      <OtcAllocCounterpart
        allocCounterpartId={alloc.counterpartId}
        errorCounterparty={error.Counterparty}
        rfqId={rfqId}
        legId={legId}
        allocationId={alloc.uuid}
      />

      <OtcAllocPdc rfqId={rfqId} counterpartId={alloc.counterpartId} />

      <OtcAllocSize
        allocation={alloc}
        legId={legId}
        allocationIndex={index}
        strategyId={strategyId}
        uiSizeType={uiSizeType}
        isErrorSize={error.Size}
      />

      <IndependentAmount
        alloc={alloc}
        rfqId={rfqId}
        legId={legId}
        showValueError={error.IaValue}
        displayIAToBeBooked={shouldShowIAToBeBookedColumn}
        showTypeError={error.IaType}
        uiSizeType={uiSizeType}
      />

      <IAValueDate allocationKey={{ allocationId: alloc.uuid, legId, rfqId }} />

      <OtcAllocMedia allocation={alloc} rfqId={rfqId} legId={legId} />

      <If condition={isConfirmationModeDisplayed}>
        <OtcAllocConfirmationMode allocation={alloc} rfqId={rfqId} legId={legId} />
      </If>

      <AllocationMcaEligibility legId={legId} allocUuid={alloc.uuid} />

      <OtcAllocMarkitWireOptions
        hasMarkitAlloc={hasMarkitAlloc}
        allocation={alloc}
        rfqId={rfqId}
        legId={legId}
      />

      <OtcAllocForcedReason
        shouldShowForcedReasonColumn={shouldShowForcedReasonColumn}
        allocation={alloc}
        rfqId={rfqId}
        legId={legId}
      />

      <If condition={hasOtcAllocationBookingStep}>
        <NeosBookingId
          defaultBookingId={bookingInfo?.defaultBookingId}
          bookingTimeStamp={bookingInfo?.lastUpdateTime}
          bookingId={bookingInfo?.bookingId}
          bookingStatus={bookingInfo?.status}
          dataE2e="otc-allocation-booking-id"
          overlayTriggerPlacement="top"
          onBookingIdChanged={newBookingId => onBookingIdChanged(alloc.uuid, newBookingId)}
        />
      </If>

      <If condition={meteorBookingStep?.meteorTradeId?.id !== undefined}>
        <NeosBookingId
          isReadOnly
          onBookingIdChanged={null}
          bookingId={meteorBookingStep?.meteorTradeId?.id}
          message={meteorBookingStep?.message}
          bookingStatus={meteorBookingStep?.status}
          dataE2e="otc-allocation-meteor-trade-booking-id"
          overlayTriggerPlacement="top"
        />
      </If>

      <If condition={meteorBookingStep?.meteorExGenId?.id !== undefined}>
        <NeosBookingId
          isReadOnly
          onBookingIdChanged={null}
          bookingId={meteorBookingStep?.meteorExGenId?.id}
          message={meteorBookingStep?.message}
          bookingStatus={meteorBookingStep?.status}
          dataE2e="otc-allocation-meteor-exec-booking-id"
          overlayTriggerPlacement="top"
        />
      </If>

      <If condition={haveSomeBrokenLinkAllocations}>
        <BrokenLink hasBrokenLink={hasBrokenLink} isAllocBookingId />
      </If>

      <If condition={areFileIconsDisplayed}>
        <div className="d-flex">
          <DownloadAllocationFile
            rfqId={rfqId}
            allocId={alloc.uuid}
            isManuallyUpload={!!bookingInfo?.isManuallyUpload}
          />
          <UploadFile rfqId={rfqId} allocId={alloc.uuid} />
        </div>
      </If>

      <OtcAllocationSecondaryTypeWidget rfqId={rfqId} legId={legId} allocationId={alloc.uuid} />

      <OtcAllocSalesMargin
        isSalesMarginDisplayed={isSalesMarginDisplayed}
        salesMarginValue={salesMarginValue}
      />

      <SalesCreditManualBooking
        allocation={alloc}
        bookingId={bookingInfo?.bookingId}
        hasFailedSalesMarginBookings={hasFailedSalesMarginBookings}
        salesMarginValue={salesMarginValue}
        rfqId={rfqId}
        legId={legId}
      />

      <SecondaryAllocationsFields
        strategyId={strategyId}
        allocationId={alloc.uuid}
        legId={legId}
        rfqId={rfqId}
      />
    </>
  );
};
